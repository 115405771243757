import React from "react"
import tw from "twin.macro"
import Seo from "@components/seo"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import PrimaryCTA from "@components/PrimaryCta"

// Assets
import logo from "@images/logos/xywav-logo.svg"

const SucThankYouPage = ({ location }) => {
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo
        title="Thank You for Sharing Your Story | XYWAV®"
        location={location}
      />
      <section tw="relative lg:bg-white-lilac pt-10 pb-0 overflow-hidden md:(pt-20 pb-28)">
        <img
          src={logo}
          width="397"
          tw="hidden lg:(block absolute right-[94px] top-8)"
          alt=""
        />
        <Container>
          <h1
            css={[
              tw`font-cocon font-bold text-[32px] leading-[1.15] xl:(text-4xl)`,
            ]}
          >
            Thank you for sharing your story!
          </h1>
          <div tw="my-6">
            Keep an eye out for <span tw="md:hidden">more</span> information.
          </div>
          <PrimaryCTA url="/" mdSmall={true} width={tw`lg:w-fit`}>
            BACK TO HOME
          </PrimaryCTA>
        </Container>
      </section>
    </Layout>
  )
}

export default SucThankYouPage
